
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonSearchbar,
  IonRow
} from "@ionic/vue";
import AccountBadge from "@/components/account/AccountBadge.vue";
import FilterButton from "@/components/filter/FilterButton.vue";
import { addCircleOutline } from "ionicons/icons";
import { useStore } from "@/services/store";
import { getAccessorType } from "typed-vuex";
import budgetModule from "../store";
import { useAccessor } from "..";

export default {
  name: "Budget",
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonSearchbar,
    IonRow,
    AccountBadge,
    FilterButton
  },
  setup() {
    return {
      addCircleOutline
    };
  }
};
